@import "./theme.scss";
//@import url("https://fonts.google.com/specimen/Poppins");

// @font-face {
//   font-family: Poppins;
//   src: url(poppins/Poppins-Light.otf);
// }

body {
  //background-color: #f5f5f5;
  color: #000000;
  font-family: Poppins;
}
.primary {
  color: $headerColor;
}

.inline-block {
  display: inline-block;
}

.align-right {
  float: right;
  clear: right;
}

.mb-m {
  margin-bottom: 50px;
}

.main-container {
  margin-top: 90px;
  margin-right: 10%;
  margin-left: 10%;
}

.block-container {
  display: inline-block;
}

.signup-container {
  .MuiStepConnector-root {
    margin-left: 50%;
    .MuiStepConnector-line {
      border-width: 0px;
      display: block;
      border-style: dashed;
      border-color: #bdbdbd;
      border-left-width: 1px;
      min-height: 24px;
    }
  }
}

.Navigation {
  .active {
    h6 {
      color: #f48351;
    }
  }
}

.errorField fieldset {
  border-color: #f48351 !important;
}

.MuiDataGrid-columnHeaders {
  text-align: center;
}

.MuiDataGrid-cell {
  justify-content: center !important;
  display: grid !important;
}

.view-trend-container {
  .Table,
  .content-header {
    border: 1px solid rgba(211, 211, 211, 0.5);
    border-radius: 5px;
  }

  .content-header {
    padding-left: 20px;
    margin-bottom: 20px;

    p {
      margin: 10px;
    }
  }

  .TableHead {
    border: 1px solid rgba(211, 211, 211, 0.5);
    border-bottom: 0px;
  }
}

.buttonStyle {
  background-color: #f48351 !important;
  border: none !important;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  color: #ffffff !important;
}

.buttonStyle1 {
  background-color: gray !important;
  border: none !important;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  color: #ffffff !important;
}
.MuiDataGrid-columnHeaderTitleContainer {
  justify-content: center !important;

  .MuiDataGrid-columnHeaderTitle {
    font-weight: bold !important;
  }
}

.css-8jmfh6-MuiButtonBase-root-MuiButton-root {
  text-transform: capitalize !important;
}

.report-download-button {
  margin-top: 35px !important;
  background: #6572e4 !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  float: right !important;
  padding: 8px !important;
  text-transform: none !important;
}

.report-download-button:disabled {
  margin-top: 35px !important;
  background: #6572e4 !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  float: right !important;
  padding: 8px !important;
  text-transform: none !important;
  color: #ffffff !important;
  opacity: 0.5 !important;
}

.MuiDataGrid-columnHeader {
  border: 1px solid rgba(224, 224, 224, 1);

  .MuiDataGrid-iconSeparator {
    color: transparent !important;
  }
}

.segment-label,
.segment-value {
  font-family: "Poppins";
}

.chart-body {
  height: 370px;
  justify-content: center;
  display: flex;
  align-items: center;
}
