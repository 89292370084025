body {
  height: 100%;
}

.login-container {
  display: block;
  margin: auto;
  margin-top: 3%;
  border-radius: 16.9787px;
  border: 1.7px;
  border-color: #EAEAEA;
  max-width: 90%;
  min-width: 20%;
  //width:20%;
  padding: 0;
}

.signup-container {
  width: 600px;
  display: block;
  margin: 10px 0px;
  margin-top: 1vh;
  box-shadow: 5.09362px 11.8851px 25.4681px -6.79149px rgba(0, 0, 0, 0.1);
  border-radius: 16.9787px;
}
.signup-container1 {
  width: 900px;
  display: block;
  margin: auto;
  margin-top: 1vh;
  box-shadow: 5.09362px 11.8851px 25.4681px -6.79149px rgba(0, 0, 0, 0.1);
  border-radius: 16.9787px;
}

.loginBackground {
  background: url(../assets/images/btm_bg.png),
    url(../assets/images/btm_bg1.png);
  background-position: bottom;
  background-repeat: no-repeat;
  position: fixed;
  height: 100%;
  width: 100%;
}

.signUpBg {
  background: url(../../common/assets/images/SignUpBg1.svg),
    url(../../common/assets/images/SignUpBg2.svg);
  background-position: bottom;
  background-repeat: no-repeat;
  position: fixed;
  height: 65%;
  width: 100%;
}
.signUpBg1 {
  background: url(../../common/assets/images/SignUpBg1.svg),
    url(../../common/assets/images/SignUpBg2.svg);
  background-position: bottom;
  background-repeat: no-repeat;
  position: fixed;
  height: 4%;
  width: 100%;
  top: 98%;
}

.signUpBg2 {
  background: url(../../common/assets/images/SignUpBg1.svg),
    url(../../common/assets/images/SignUpBg2.svg);
  background-position: bottom;
  background-repeat: no-repeat;
  height: 80%;
  width: 100%;
  top: 20%;
  position: fixed;
}

.avy_logo {
  padding: 20px 0px;
  width:50%
}

.avy_logo .avy-img {
  position: absolute;
  top: 10px;
  left: 10px;
}

.pre_img {
  display: block;
  margin: auto;
  width: 100px;
}
.pre_img1 {
  display: block;
  margin: auto;
  height: 36%;
}

.pre_caption {
  font-family: "Poppins";
  font-style: normal;
  text-align: center;
  margin: auto;
  padding: 10px 0px;
  color: #868686;
}

.login_contact {
  float: right;
  border: 1.1px solid #6572e4;
  border-radius: 5px;
  margin-top: 10px;
  margin-right: 20px;
  padding: 3px 10px;
  color: #6572e4;
}
.login_contact_content:hover {
  background: none;
  background-color: #f5f5f5 !important;
}
.login_contact_content1{
  padding: 2px 20px !important;
  text-transform: capitalize !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}
.login_contact_content1:hover {
  background: none;
  background-color: #ffffff !important;
}

.login_contact_content {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12.6px;
  line-height: 26px;
  text-align: center;
  color: #6572E4;
}

.login_welcome {
  margin: auto;
  text-align: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 37px;
  color: #000000;
  margin-top: 5%;
}

.cre_acc {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 39px;
  text-align: center;
  color: #000000;
}

.login_cont {
  text-align: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #868686;
  margin: 0;
  padding: 0;
}
.forgot_cont {
  text-align: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #000000;
  margin-bottom: 5%;
  margin-top: 5%;
}

.css-mhc70k-MuiGrid-root > .MuiGrid-item .purple-add {
  background: #f68f61 !important;
  margin: auto;
}

.purple-add {
  // background: #f68f61 !important;
  background: rgba(244, 131, 81, 1) !important;
  margin: auto;
}

.gray-add {
  background: gray !important;
  margin: auto;
  cursor: default !important;
}

.copy-posi {
  position: relative;
  text-align: center !important;
}

.Copyright {
  text-align: center !important;
  color: #ffffff;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  bottom: 0px !important;
  position: fixed;
  width: 100%;
}

.login_label {
  font-style: normal;
  font-weight: 400;
  font-size: 0.8em;
  line-height: 24px;
  margin: 0px 0px !important;
  text-align: left;
  padding-left: 0px;
}
.newAccReDirect {
  font-style: normal;
  font-size: 0.8em;
  text-align: center;
}
.signup_link {
  padding-left: 5px;
}

.signup_button {
  background: #f48351 !important;
  color: #ffffff !important;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin: 5px 50px !important;
  border-color: none;
  padding: 5px 50px;
  width: 50%;
  text-transform: capitalize !important;
}
.sendcode_button {
  background: #f48351 !important;
  border-radius: 5px !important;
  color: #ffffff !important;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin: 5px 5px !important;
  border-color: #f48351 !important;
  padding: 5px 5px;
  width: 50%;
}

.year {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  //line-height: 38px;
  text-align: center;
  color: #53ccf2;
}
.freePlanPrice
{
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  //line-height: 38px;
  text-align: center;
  color: #53ccf2;
}
.standardPlanPrice
{
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  //line-height: 38px;
  text-align: center;
  color: #F8CA50;
}
.premiumPlanPrice
{
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #EE6F53;
}

.threemonths {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  //line-height: 38px;
  text-align: center;
  color: #f8ca50;
}

.sixmonths {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  //line-height: 38px;
  text-align: center;
  color: #ee6f53;
}

.billed_monthly {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #3e3e3e;
}
.free_plan {
  background: rgba(83, 204, 242, 0.1);
  border: 0.5px solid #53ccf2;
  border-radius: 4px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #53ccf2;
  padding: 10px 10px;
  display: inline-block;
  opacity: 60%;

}
.standard_plan {
  background: rgba(248, 202, 80, 0.1);
  border: 0.5px solid #f8ca50;
  border-radius: 4px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #F8CA50;
  padding: 10px 10px;
  display: inline-block;
  opacity: 60%;
}
.premium_plan {
  background: rgba(238, 111, 83, 0.1);
  border: 0.5px solid #ee6f53;
  border-radius: 4px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #ee6f53;
  opacity: 60%;
  padding: 10px 10px;
  display: inline-block;
}
.subsc_content {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 17px;
  color: #3e3e3e;
  text-align: justify;
}
.subActivePlanSpan
{
  
  font-weight: 600;
  font-size: 13px;
  color: #000000;
}
.subActivePlan{

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  color: #3e3e3e;
  text-align: justify;

}
.sub_align
{
  text-align: center;
  padding: 5px 15px;
}

.sub_align1
{
  padding: 10px 15px;
}
.abc {
  background: #f8ca50;
  border-radius: 10px 10px 0px 0px;
  margin-top: 0px;
  height: 15px;
}
.freePlan {
  background: #53CCF2;
  border-radius: 10px 10px 0px 0px;
  margin-top: 0px;
  height: 15px;
}
.StandardPlan {
  background: #F8CA50;
  border-radius: 10px 10px 0px 0px;
  margin-top: 0px;
  height: 15px;
}
.PremiumPlan {
  background: #EE6F53;
  border-radius: 10px 10px 0px 0px;
  margin-top: 0px;
  height: 15px;
}
.abcd {
  border-top: 10px #f8ca50;
  border-radius: 0px 0px 0px 7.5px;
  padding: 0px !important;
}
.activePlan {
  border: 1px solid #ee6f53;
  padding: 0px !important;
  border-radius: 10px;
}

.inactivePlan {
  padding: 0px !important;
  border: 1px solid #0000001f;
  border-radius: 10px;
}
.frgtpwdlink {
  margin-right: 0px;
  float: right;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #6572e4;
}
.rememberme {
  margin-right: 15px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
  color: #6572e4;
  text-decoration: none;
  text-align: left;
}
.css-mhc70k-MuiGrid-root > .MuiGrid-item .rememberme {
  text-align: left !important;
}
.otp_entry {
  width: 1.5em;
  height: 1.5em;
}
.verifyAccount-img {
  border: 1px solid #a7a7a7;
  margin-top: 10px;
  width: 95%;
  border-radius: 5px;
}
.verifyAccount-list {
  padding: 2px 4px !important;
}
.verifyAccount-listitem {
  padding: 1px 0px !important;
}
.file-img1 {
  padding: 2px 0px !important;
}

.OTP_Grid {
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 5% !important;
  // margin-bottom: 10% !important;
}

.otpInput{
  width: 2.5em;
    text-align: center;
    height: 2.5em;
    border-radius: 7px;
    background: #F6F5FA;
    border: 0.75px solid #f6f5FA;
}
.login_radius{
  border-radius: 10px !important;
  padding: 1rem !important;
}

.css-1cnyj60-MuiInputBase-root-MuiOutlinedInput-root, .css-1b64r4u-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 6px !important;
}

.sign-up-button{
  text-transform: capitalize !important;
}
.services-list{
  text-align: start;
    font-size: 13px;
    list-style-type: square;
    line-height:2em;
}
