body {
  margin: 0px;
  font-family:Poppins;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.outletContainer {
  width: calc(100% + 25px) !important;
  margin-top: -2px !important;
  margin-bottom: 1em;
  /* height: calc(100% - 500px) !important;
  overflow: auto; */
}