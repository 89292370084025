@import "../../theme.scss";

// #header {
//   padding: 15px;
//   /* height: 50px; */
//   position: fixed;
//   color: $headerInvertColor;
//   width: 100%;
//   background-color: $headerColor;
//   padding-right: 10%;
//   padding-left: 10%;
//   top: 0px;
// }

body {
  background-color: #f5f5f5 !important;
  font-family: "Poppins";
  width: 100%;
}
#header.colored {
  color: #000000;
  padding: 0px 0px;
  box-shadow: 0px 1px rgba(0, 0, 0, 0.2);
  margin: auto;
  margin-left: 6.7em;
  a,
  button, button:hover{
    border: none;
    padding: 0px 10px;
    background: none; 
    background: rgba(248, 248, 248, 1);
    border-radius: 5px;
    padding-right: 2px;
    margin: 0px 0px;
  }
  button.colored {
    background-color: rgba(255, 0, 0, 0);
  }
}
#header .prescotitle {
  color: #f5a787;
}
#header.primary {
  background-color: #f44336;
}
#header .data_entry {
  color: #000000;
  // font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
}
#title {
  font-size: 2em;
  a {
    text-decoration: none;
  }
}
.info {
  font-size: small;
  padding: 9px;
  font-weight: bold;
}
.MuiToolbar-root {
  padding-left: 0px !important;
}
#header a {
  color: $headerColor;
}
#header {
  color: #1976d2;
  background-color: #fff;
  top: 0px;
  right: 0px;
  left: 0;
  z-index: 1100;
  box-shadow: 0px 2px 2px 0px #000000;
  position: fixed;
  margin: auto;
  // max-width: 91.6%;
  width: auto;
}
.header-fixed {
  position: fixed;
  margin: auto;
  background-color: #fff;
  box-shadow: 2px #000000;
}
.dataoutlet {
  padding: 5px 0px;
  margin: 0px 0px;
  margin-top: 10vh;
  margin-left: 2em;
}

.new-bmrn {
  .dataoutlet {
    margin-top: 0px;
  }
}

.dataoutlet1 {
  padding: 5px 0px;
  margin: 0px 0px;
  margin-top: 1vh;
  margin-left: 2em;
}
.logindiv {
  height: 100%;
  margin-right: 20px;
}
.Data_Entry {

  font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 1.3em;
    line-height: 45px;
    padding-left: 20px;
    letter-spacing: 0.03em;
}
/*Sidebar CSS Starts Here*/
.Navigation {
  margin: 0px 1px;
  font-size: 16px;
  font-weight: 700;
  padding: 0px 0px;
  background: #ffffff;
  height: 100vh;
  width: 100%;
}
.sidebar {
  margin: 15px 0px 0px 0px;
  font-size: 16px;
  font-weight: 700;
  color: #f5a787;
  text-align: center;
  // font-family: "Poppins";
}
.sidebar .logo {
  width: 50%;
}
.nav-position {
  position: fixed;
  z-index: 1110;
  background: #ffffff;
}

nav ul {
  list-style-type: none;
  padding: 0px 10px;
}
nav .sidebar-ul {
  list-style-type: none;
  padding: 0px 15px;
}
nav .sidebar-ul .sidebar-menuitems {
  text-align: center;
  margin: 0px 0px;
  margin-bottom: 20px;
}
nav .sidebar-ul .sidebar-menuitems .sidenavbar-img {
  height: 50px;
  width: 55px;
}

nav .sidebar-ul .sidebar-menuitems .sidenavbar-logo {
  width: 75px;
  margin-bottom: 20px;
}

nav .sidebar-ul .sidebar-menuitems:hover {
  color: #f48351;
}
.sidebar-menuimgs {
  width: 20px !important;
  height: 20px !important;
}

nav ul .sidebar-help {
  text-align: center;
  margin: 0px 25px;
  margin-top: 30px;
}
nav ul .sidebar-help .sidenavbar-img {
  height: 55px;
  width: 55px;
}
nav ul li .sidenavbar {
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  color: #626262;
  display: block;
  padding: 5px 0px;
  text-align: center;
}

nav ul li .sidenavbar:hover {
  color: #f48351;
  fill: #f48351;
}

.bmrncontent {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 0.75em;
  line-height: 18px;
  padding: 0px 0px 0px 0px;
  color: #878787;
  text-transform: uppercase;
  margin-bottom: 1em;
}
.presccontent {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 0.75em;
  line-height: 18px;
  padding: 0px 0px 0px 0px;
  color: #878787;
  text-transform: uppercase;
  margin: 10px auto;
  text-align: center;
  margin-bottom: 1em;
}

.presccontent span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.05em;
  line-height: 18px;
  color: #000000;
  text-transform:none;
}
.PrescTitleHeader{
  text-transform:none;
}
.bmrncontent span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1.15em;
  line-height: 18px;
  color: #000000;
  text-transform: none;
}
.bmrncontent img {
  background-color: transparent;
  height: 30px;
  width: 30px;
  padding: 6px 0px;
}
.datacontent {
  margin-left: 2em;
}
.readingid {
  color: #f48351;
  padding: 5px 0px;
  border-radius: 50%;
  text-align: center;
  width: 30px;
  border: 1px solid #f48351;
  font-size: 12px;
  font-family: "Poppins";
  margin: 5px 10px;
  float: right;
}
.bmrn {
  color: #878787;
}
#profilename .css-1guk29 {
  float: right;
}
.username {
  // font-family: "Poppins";
  font-size: 0.5em !important;
  font-family: "Poppins" !important;
  padding: 0px 0px !important;
  color: #3e3e3e !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  margin: 10px 10px !important;
}
.header-userName{
  width: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bmrncontent .ReadingIcon {
  height: 40px;
  width: 40px;
  float: right;
  padding: 0px 0px;
}
.left-navigation {
  position: fixed;
  display: inline;
  margin: 0px auto;
  background: #ffffff;
}
nav ul li .active {
  padding: 5px 0px;
  color: #f48351;
}
.nonactive {
  margin-bottom: 20px;
  color: #000;
}
.active {
  margin-bottom: 20px;
  color: #f48351;
}
.active .nav_fill > path {
  fill: #f48351;
  color: #f48351;
}
.customCell{
  border:1px solid #000000;
}
.footer-copyright{
    background: #ffffff;
    background-color: #FFFFFF;
    justify-content: center;
    display: flex;
    position: fixed;
    left: 0px;
    bottom: 0;
    right: 0px;
    left: 0;
    z-index: 1100;
    position: fixed;
    margin: auto;
    width: auto;
    margin-left: 6.7em;
    box-shadow: 0px 1px rgba(0, 0, 0, 0.2);
}
.footer-content{

  font-size: 1.0em;
  font-family: "Poppins";
  padding: 0px 0px;
  color: #000000;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}