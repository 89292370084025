.css-1dlyelz-MuiButtonBase-root-MuiToggleButton-root.Mui-selected {
  background: rgba(101, 114, 228, 1);
}
.dataentry_input2 {
  width: 100%;
  margin: 7px 0px !important;
}
.dataentry_input1 {
  width: 60%;
  margin: 5px 0px !important;
}

.dataentry_input_opinion {
  width: 95%;
  margin: 7px 0px !important;
}

.dataentry_inputtime {
  width: 80%;
  margin: 5px 0px !important;
}

.dataentry_select {
  width: 70%;
  margin: 5px 0px;
}

.dataentry_select1 {
  width: 80%;
  margin: 5px 0px;
}

.Toggle_input {
  width: 90%;
  padding: 0px 0px;
  margin: 0px 0px !important;
}

.tab-content {
  width: 100% !important;
}

.formSection {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  text-transform: none;
}

.formSection .signup_button,
.signup_button:hover {
  border: "none" !important;
  background-color: #f48351;
}

.formSection .MuiButton-outlinedSizeMedium {
  width: 100% !important;
  color: #000000;
  //font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  text-transform: none;
  border: 0.8px solid #a3aaef;
  box-shadow: none;
  margin: 10px 0px;
  border-radius: 6px;
}

.formSection .purple,
.formSection .purple:hover {
  background: #f48351;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  color: #ffffff;
  border: none !important;
}
.formSection .MuiButton-containedSizeMedium:hover,
.MuiButton-outlinedSizeMedium:hover {
  //background: #6572e4 !important;
  box-shadow: none;
  color: #ffffff;
}

.formSection .MuiButtonBase-root .back_button {
  border: 1px solid #ffffff !important;
  color: #f56d6d;
}

.MuiGrid-root .formSection {
  background: #ffffff;
  height: 100%;
}

#mother-medical-form .MuiOutlinedInput-notchedOutline {
  border-color: #6572e4;
}

#profiletab {
  .MuiButtonBase-root {
    border: 1px solid #6572e4;
  }
}

.MuiToggleButton-root {
  color: black !important;
}

.Mui-selected {
  color: black !important;
  background-color: rgba(101, 114, 228, 0.1) !important;
}

.css-1dlyelz-MuiButtonBase-root-MuiToggleButton-root.Mui-selected
  #mother-medical-form
  .floating-btn {
  position: fixed;
  bottom: 25px;
  background-color: #fff;
  border-color: #f5a787 !important;
  color: #f5a787 !important;
  right: 25px;
  padding-left: 3;
  padding-right: 3;
}

.formSection .MuiPaper-root {
  height: 100%;
}

.BMRNScoreGeneration {
  color: #6572e4;
  border: 1px solid #6572e4;
  padding: 3px 4px 4px 3px;
  display: block;
  margin: auto;
  margin-bottom: 2px;
  // margin-left: 55px;
  margin-top: 21px;
  text-align: center;
  width: 40%;
  font-size: 13px;
}

.BMRNScoreGeneration span {
  color: #000000;
}

.ScoreGenerationScore {
  padding-top: 5px;
  width: 45%;
  height: 40%;
  font-size: 30px;
  border: 1px solid;
  margin-top: 40px;
  margin-bottom: 20px;
  margin-left: 50px;
  text-align: center;
}

#score-generation .DownloadReports {
  width: 90%;
  background-color: #f48351;
  font-size: 12px;
  font-weight: 500;
  padding: 0.8rem;
}

#score-generation .ReferralDoctor {
  width: 90%;
  background-color: #6572e4;
  font-size: 12px;
  font-weight: 500;
  padding: 0.8rem;
}

#score-generation .PrescriptionDocument {
  width: 90%;
  font-size: 12px;
  font-weight: 500;
  background-color: #a3aaef;
  padding: 0.8rem;
}

.round-bordered {
  border: 1px solid #f0f1fc;
  border-radius: 4px;
  min-height: 300px;
  margin: 25px 10px;
}

.round-read-bordered {
  border: 1px solid #f0f1fc;
  border-radius: 4px;
  padding: 0px 10px;
  margin: 25px 10px;
}

.report-header {
  height: 20px;
  padding: 10px 0px;
  background-color: #f0f1fc;
}

.report-content {
  min-height: 100px;
}

.No_reports_Found {
  text-emphasis-color: #878787;
  font-size: 10px;
  padding: 10px;
  //text-align: center;
  //margin: auto;
}

.CaseSheets {
  font-size: 12px;
  padding-left: 10px;
}

.color-indicator {
  width: 25px;
  height: 18px;
  border-radius: 5px;
  display: block;
  margin: auto;
}

.bg-green {
  background-color: #74d976;
}

.bg-orange {
  background-color: #f9d372;
}

.bg-red {
  background-color: #f56d6d;
}

.header {
  margin-bottom: 1px solid #6572e4;
  border-bottom: #a3aaef;
}

.uploadClass {
  text-align: right;
}

.score-grid {
  padding: 70px 50px;
}
.login-dataEntry-input {
  width: 100%;
  font-size: 0.8em !important;
  margin: 5px 0px !important;
}
.dataentry_input {
  width: 90%;
  font-size: 0.8em !important;
  margin: 5px 0px !important;
  text-transform: capitalize !important;
}
.dataentry_time {
  width: 80%;
  font-size: 0.8em !important;
  margin: 5px 0px !important;
}

.dataentry_input_add {
  width: 95%;
  margin: 5px 0px !important;
}

.dataentry_inputtime {
  width: 80%;
  margin: 5px 0px !important;
}
/* 
.dataentry_select {
  width: 70%;
  margin: 5px 0px;
} */

.dataentry_select_edu {
  width: 90%;
  margin: 5px 0px;
}
.dataentry_select_edu1 {
  width: 90%;
  margin: 12px 0px;
}

.select_usertype {
  width: 65%;
  margin: 5px 5px;
  padding-left: 10px;
}

.select_usertype1 {
  width: 65%;
  margin: 5px 5px;
  padding-left: 5px;
}

.select_usertype2 {
  width: 95%;
  margin: 5px 5px;
  padding-left: 10px;
}

#uploadfile {
  font-size: 11px;
  width: 50%;
  padding: 7px 0px;
  background: #6572e4;
  font-weight: 400;
  font-family: "Poppins";
}

.Toggle_input {
  width: 90%;
  padding: 1px 0px;
  margin: 0px 0px !important;
  font-size: 0.98em;
}

.dataentry_label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 0.9em;
  line-height: 24px;
  margin-top: 10px;
  text-align: left;
  width:90%;
  //padding: 0px 14px;
}
.dataentry_label1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 0.8em;
  line-height: 24px;
  margin: 2px 0px !important;
  text-align: left;
  padding: 0px 0px;
  width:90%;
}

.dataentry_label2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 0.8em;
  line-height: 24px;
  margin: 0px 0px !important;
  text-align: left;
  padding: 0px 0px;
  margin-bottom: 0.2rem;
  width:90%;
}
.formsection_label {
  //   font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  margin: 5px 0px !important;
  text-align: left;
}

.tab-content {
  width: 100% !important;
}

.formSection .MuiButton-outlinedSizeMedium {
  width: 100% !important;
  color: #000000;
  //font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  text-transform: none;
  border: 0.8px solid #a3aaef;
  box-shadow: none;
  margin: 10px 0px;
  border-radius: 6px;
  padding: 10px 0px;
}

.formSection .purple {
  background: #f48351;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  color: #ffffff !important;
}

.formSection .purple-add {
  background: #f48351 !important;
  border-radius: 5px !important;
  color: #ffffff !important;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin: 10px 20px !important;
  border: none !important;
}
.formSection .purple-add1 {
  background: #f48351 !important;
  border-radius: 5px !important;
  color: #ffffff !important;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin: 10px 0px auto !important;
  border: none !important;
  width: 50%;
}
.formSection .back {
  background: #808080 !important;
  border-radius: 5px !important;
  color: #ffffff !important;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border: none !important;
}

.formSection .back1 {
  background: #808080 !important;
  border-radius: 5px !important;
  color: #ffffff !important;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border: none !important;
  margin: 10px 20px !important;
}

.formSection .MuiButton-containedSizeMedium {
  width: 100% !important;
  margin: 10px 0px;
  color: #6572e4;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  text-transform: none;
  background: #f0f1fc;
  border: 0.8px solid #6572e4;
  border-radius: 6px;
  box-shadow: none;
  padding: 10px 0px;
}
.formSection .purple-add .MuiButton-containedSizeMedium  {
  width: 50% !important;
}

.formSection .MuiButton-containedSizeMedium:hover,
.MuiButton-outlinedSizeMedium:hover {
  //background: #f0f1fc !important;
  box-shadow: none;
  color: #6572e4;
}

.MuiGrid-root .formSection {
  background: #ffffff;
  // height: 100%;
}

#mother-medical-form .MuiOutlinedInput-notchedOutline {
  border-color: #6572e4;
}

#mother-medical-form .MuiButtonBase-root {
  border-color: #6572e4 !important;
}

#profiletab .MuiButtonBase-root {
  border: 1px solid #6572e4;
}

#mother-medical-form .floating-btn {
  position: fixed;
  bottom: 25px;
  background-color: #fff;
  border-color: #f5a787 !important;
  color: #f5a787 !important;
  right: 25px;
  padding-left: 3;
  padding-right: 3;
}

.formSection .MuiPaper-root {
  height: 100%;
}

.ScoreRisk {
  margin-top: 5px;
  margin-left: 5px;
}

.TableHead {
  background-color: #f0f1fc;
  min-height: 300px;
  margin: 25px 10px;
}

.TableRow {
  height: 2px;
}

#table-cell {
  padding: 0.7rem 0px !important;
  height: auto !important;
  line-height: 1 px !important;
  font-size: 11px;
  font-weight: 600;
  color: #3e3e3e;
}

.MuiTableCell-root {
  border-bottom: 0px #000000 !important;
}

.Table {
  border: 0.1px solid #c1c6f4;
}

.table-grid {
  padding: 50px 50px;
}

.round-bordered {
  border: 1px solid #f0f1fc;
  border-radius: 4px;
  min-height: 300px;
  margin: 25px 10px;
}

.round-bordered1 {
  border: 1px solid #f0f1fc;
  border-radius: 4px;
  min-height: 80px;
  margin: 25px 10px;
}

.report-header {
  height: 25px;
  padding: 10px 0px;
  background-color: rgba(193, 198, 244, 0.3);
}
.UploadReportsTitle {
  margin: 0px 10px;
  font-size: 1em;
  font-weight: 400;
}
.report-content {
  min-height: 100px;
}

.color-indicator {
  width: 25px;
  height: 18px;
  border-radius: 5px;
  display: block;
  margin: auto;
}

.ChartsLegends {
  width: 18px;
  height: 18px;
  border-radius: 5px;
  margin-right: 0.5rem;
}

.bg-green {
  background-color: #74d976;
}

.bg-orange {
  background-color: #f9d372;
}

.bg-red {
  background-color: #f56d6d;
}

.header {
  margin-bottom: 1px solid #6572e4;
  border-bottom: #a3aaef;
}

.Button-spacing {
  padding: 0px 20px 20px 40px;
  margin-left: 0px;
}

#reports-generation .upload-reports {
  width: 20%;
  background-color: #f48351;
  font-size: 10px;
  margin-right: 6%;
  text-transform: none;
}

.uploadClass {
  text-align: right;
}

.uploadClassdevice {
  text-align: center;
  margin: 20px !important;
  padding: 30px;
  border: 0.4px solid #878787;
  border-radius: 7px;
}

.uploadClassdevice1 {
  text-align: center;
  margin: 20px !important;
}

.uploadClassdevice1 .css-sghohy-MuiButtonBase-root-MuiButton-root {
  background-color: #f48351;
}

.score-grid {
  padding: 70px 50px;
}

.MuiDialog-paper {
  max-width: 820px !important;
}

.dialogWidth{

  max-width: 90% !important;
  min-width: 90% !important;
}

.dialogWidth1{

  max-width: 85% !important;
  min-width: 85% !important;
}

.dialogWidth1 .css-1t1j96h-MuiPaper-root-MuiDialog-paper{

  max-width: 3% !important;
  min-width: 30% !important;
}
.dialogWidth .css-1t1j96h-MuiPaper-root-MuiDialog-paper{

  max-width: 40% !important;
  min-width: 40% !important;
}
.wardDialogWidth .css-1t1j96h-MuiPaper-root-MuiDialog-paper{

  max-width: 30% !important;
  min-width: 30% !important;
}

.report-dialog .MuiDialog-paper {
  width: 100%;
}

.babyrecordsearch {
  border: 0.6px solid #6572e4;
  background: #fcfcfc;
  width: 90%;
  height: 150px;
  margin: 0px auto;
}

.bmrn-data {
  padding: 5px 15px 5px 15px !important;
  padding-bottom: none;
}

.add_bmrn {
  text-align: left;
  padding-left: 20px;
  color: #6572e4;
  font-size: 8px;
  font-family: "Poppins";
  cursor: pointer;
}

.entrytype_radio .MuiTypography-root {
  color: #3e3e3e !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.no_data {
  height: 66vh;
  min-height: 100%;
  margin: 0px auto;
  position: relative;
  vertical-align: middle;
  align-items: center;
  text-align: center;
}

.branchcontent {
  margin-top: 10px;
}

.item-container {
  background-color: #fff;
  width: 30vh;
  border: 1px solid black;
  padding: 25px 70px;
  margin: 15px 50px;
}

.new-icon {
  margin-left: 50px;
  padding: 80px 10px 5px 200px;
}

.ref-p {
  font-size: small;
}

#bmrn-form.css-480o17-MuiGrid-root > .MuiGrid-item {
  padding-top: 0px;
}

.file-img {
  padding-left: 10px;
}

.Delete-img {
  padding-left: 15px;
}

.Share-img {
  padding-left: 25px;
}

.DownLoad-img {
  padding-left: 25px;
}

.file-img-right-extreme {
  float: right;
}

.file-img-right-extreme1 {
  padding-left: 30px;
}

.file-img-right-extreme2 {
  padding-left: 36%;
}

.file-img-extreme-right {
  float: right;
}

.file-img-filter {
  padding-left: 675px;
}

.formSection .wardlist {
  background: #ffffff !important;
  border-radius: 5px !important;
  color: #626262 !important;
  border: 0.5px solid #878787;
  text-align: left;
  -webkit-justify-content: left;
  justify-content: left;
  margin-top: 10px;
}

.formSection .wardadd {
  background: #ffffff !important;
  border-radius: 5px !important;
  color: #6572e4 !important;
  border: 1px solid #6572e4;
  margin: 10px;
  width: 90% !important;
  padding: 5px 5px;
}

.branchname {
  background: rgba(244, 131, 81, 0.1);
  border: 0.8px solid #f48351;
  border-radius: 3px 3px 0px 0px;
  padding: 5px 0px;
}

.branch-wardlist {
  // border: 0.6px solid #b4b4b4;
  // border-radius: 0px 0px 8px 8px;
}

.wardlistitem .css-10hburv-MuiTypography-root {
  font-family: Poppins;
  font-style: normal;
  padding: 10px 10px;
  background: #ffffff !important;
  border-radius: 5px !important;
  color: #000000 !important;
  border: 0.5px solid #878787;
  //text-align: left;
  text-align: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin: 0px 5px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  width: 85%;
  margin: 0px auto;
}
.wardlistcheck .css-cveggr-MuiListItemIcon-root {
  min-width: 10%;
}
.branch-header {
  text-align: left;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 1em;
  line-height: 36px;
  color: #000000;
  margin-top: 0px;
}
.formSection .branch_header_group .branch_header_button {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
  color: #000000;
  width: 220px !important;
  padding: 5px 5px;
}
.editicon {
  // padding-left: 20px;
}
.Doctor-input {
  //padding-right: 10px;
}
.ref-add-button {
  // width: 90%;
  //height: 30px;
  // font-size: 10px;
  background-color: #6572e4;
}
.buttonClassSelectRef {
  padding-left: 30px;
  padding-top: 15px;
}
.Ph-input {
  padding-top: 15px;
}

.ref-verify-button {
  padding-top: 15px;
}

.cre_acc {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 39px;
  text-align: center;
  color: #000000;
}

.Stepper {
  margin-bottom: 5vh;
  margin-top: 2vh;
}

.account_bottom {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #3e3e3e;
  margin-top: 20px;
}

.continue {
  margin: auto;
  display: block;
  margin-top: 0px;
}

.subsc_plans {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 33px;
  color: #3e3e3e;
}

.identity_verify {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: #000000;
  margin-top: 10px;
  text-align: center;
}

.identity_content {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 25px;
  color: #3e3e3e;
}

.terms_box {
  border: 0.5px solid #878787;
  border-radius: 10px;
  padding: 5px 5px;
  text-align: inherit;
}

.terms_box p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  padding-left: 10px;
}

.ref-verify-button {
  padding-top: 15px;
}

.doctor-details {
  text-align: end;
}

.ref-doctor-overflow {
  height: 150px;
  overflow: auto;
}

.GauzeChart {
  padding-top: 50px;
}
.GauzeChart_ref {
  padding-top: 5px;
  margin: auto;
}
.GauzeChart {
  & .speedometer {
    width: 100% !important;
  }
}

.dashboard-title {
  text-align: left;
  font-size: 1em;
  font-weight: 600;
}

.view_Reports {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #878787;
  background: #f98f60;
  border-radius: 4px;
  text-transform: none;
}

.readingsummary_name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #7882dd;
}

.summary_data {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
  color: #3e3e3e;
  padding-left: 20px;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper .report-dialog {
  width: 100%;
}

.file-align {
  min-width: 10px !important;
}

.pt_info {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  text-align: left;
}

.pt_info_cont {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 21px;
  letter-spacing: 0.02em;
  color: #878787;
}
.pt_info_details {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  letter-spacing: 0.02em;
  color: #878787;
}
.ptinfo_data {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 0.02em;
  color: #000000;
}
.css-11qr2p8-MuiButtonBase-root-MuiButton-root .pt_info_viewtrend {
  background: rgba(244, 131, 81, 0.15);
  border-radius: 4px;
}

.formSection .pt_info_viewtrend {
  background: rgba(244, 131, 81, 0.15);
  border-radius: 4px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-decoration-line: underline;
  color: #f48351;
  margin-top: 3rem;
}

.dr_opi_rpts {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
  color: #000000;
  text-align: left;
}

.dadata {
  font-family: "Poppins";
  font-style: normal;
  font-size: 12px;
  // width: 10%;
  text-align: center;
  font-weight: 600;
}
.gridheader_font {
  font-family: "Poppins";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
}

.dbtablereading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  display: inline-block;
  width: 40px;
  align-items: center;
  text-align: center;
  color: #f48351;
  background: rgba(244, 131, 81, 0.15);
  border-radius: 4.44444px;
  padding: 5px;
  margin: 0px;
}

.radio_sort {
  padding: 2px 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 8px;
}

.sorting_radio .MuiTypography-root {
  color: #3e3e3e;
  font-size: 14px;
  font-weight: 600;
}

.sorting_radio:checked .radio_sort:checked .MuiRadio-root.Mui-checked {
  color: #6572e4;
}

.css-mhc70k-MuiGrid-root > .MuiGrid-item .addbmrn-header {
  text-align: left;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-top: 5px;
}

.css-mhc70k-MuiGrid-root > .MuiGrid-item .addbmrn-content {
  text-align: left;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1d1d1d;
}

.css-2s90m6-MuiAvatar-root {
  height: 200px !important;
  width: 200px !important;
}

.avatar_content {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #7a7a7a;
}

.profilepic .css-1pqm26d-MuiAvatar-img {
  width: 100%;
}

.Admin_Access_Table {
  margin: 10px auto;
}

.Admin_Access_header {
  margin: 10px 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #000000;
}
.changePassword {
  display: block;
  float: right;
  margin-right: 35px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-decoration-line: underline;
  color: #6572e4;
  background: #f0f1fc;
  border-radius: 2.88888px;
  padding: 5px 5px 5px 5px;
}
.changePassword:hover {
  cursor: pointer;
}
.donut_class {
  height: 350px !important;
  padding: 10px 10px;
  margin: 10px auto;
  width: 98%;
}
.donut_class1 {
  height: 350px !important;
  padding: 10px 10px;
  margin: 10px auto;
  width: 98%;
}

.finalBarChart_class {
  height: 470px !important;
  padding: 10px 10px;
  margin: 10px auto;
  width: 98%;
}
.chart_data {
  border: 0.5px solid rgb(210, 210, 210);
  border-radius: 0.5rem;
  width: 100%;
}
.chart_title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16.3881px;
  line-height: 25px;
  color: #000000;
  border-bottom: 0.4px solid rgb(210, 210, 210);
  //border-bottom: 0.4px solid #878787;
  padding: 10px 10px;
  margin: 0px 0px;
}
.reports_chart_title {
  margin: 5px 5px;
  margin-bottom: 10px;
}
.add_reading {
  padding: 0px 0px;
  margin: 0px 0px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1.15em;
  line-height: 18px;
  text-align: center;
  color: #626262;
}
.reading_History {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #878787;
}
.addicon {
  padding: 0px 10px !important;
}
.branch_Add {
  float: right;
  background: #6572e4 !important;
  color: #ffffff !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  line-height: 18px !important;
  padding: 10px 10px !important;
  text-transform: none !important;
}
.branch_map_length {
  padding: 10px 1px !important;
  float: right !important;
}
.branch_map_title {
  padding: 10px 1px !important;
}
.prescreport {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 36px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  color: #ffffff;
  background: #f48351;
  padding: 2px 10px;
  margin: 0px auto;
}
.generatedon {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  color: #3e3e3e;
  float: right;
}
.generatedon1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  color: #3e3e3e;
  margin: 5px auto;
  text-align: center;
}
.prescfooter {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 24px;
  text-align: center;
  color: #7a7a7a;
  margin-top: 30px;
  border-top: 1.05663px solid rgba(101, 114, 228, 0.4);
}
.presc_ptdetail {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 30px;
  color: #000000;
  border: none;
  margin: 1px 10px;
  padding: 2px 0px;
}
.padetais_cont {
  color: #000000;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 37px;
  letter-spacing: 0.05em;
}
.padetais_label {
  color: #878787;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 27px;
  letter-spacing: 0.05em;
  display: block;
}

.report-header-value {
  padding: 15px 0px;
  font-size: 18px;
  font-weight: 600;
  color: #000000;
  text-align: center;
}
.css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
  width: 100%;
}
.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainerContent {
  width: 100%;
}
.css-1e2bxag-MuiDataGrid-root
  .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)
  > .MuiDataGrid-cell {
  width: 12%;
  display: initial;
  align-items: center;
  margin: 0px 0px;
  max-width: 12%;
  min-width: 12%;
}
.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-row {
  // margin: 10px 0px 0px 0px;
  margin: 0px;
}

.acrossBtn:hover {
  background-color: transparent !important;
}

.acrossBtn .MuiTouchRipple-root span {
  opacity: 0 !important;
}

.speedMeterOverride {
  justify-content: center;
  display: flex;
  & .speedometer {
    //width: 100% !important;
    & .segment-value {
      font-weight: bold !important;
      font-size: 12px !important;
    }
    & .current-value {
      fill: #000 !important;
      font-size: 12px !important;
      font-weight: bold !important;
    }
  }
}

.file-icon {
  min-width: 35px !important;
  margin-top: 8px;
}

.file-name {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 12px;
}

.dashboard-file-name {
  margin-top: 12px;
}

.file-action-icons {
  min-width: 30px;

  .DownLoad-img {
    padding-left: 5px;
  }
}
.doughnutChart,
.genderLineChart {
  padding: 16px !important;
}

.deleteIcon {
  padding: 5px 10px;
  background: rgba(241, 101, 101, 0.18);
  border-radius: 5px;
  margin-left: 10px;
}

.tag-line-border {
  border: 0.5px solid rgba(101, 114, 228, 0.2);
}
.babyHealthTable {
  .MuiDataGrid-columnHeaderTitleContainer {
    justify-content: center !important;

    .MuiDataGrid-columnHeaderTitle {
      color: rgba(135, 135, 135, 1) !important;
      font-weight: 400 !important;
    }
  }
}
.babyBodyData{
  font-weight: 600 !important;
  color:   rgba(0, 0, 0, 1) !important;
}

.InputAdornment{
  text-transform: none;
}
